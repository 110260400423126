@tailwind base;
@tailwind components;
@tailwind utilities;

@import './theme/variables';
@import './theme/general';
@import "./theme/states";
@import "./theme/states.icons";
// To remove, use svg icons in svg-icon.ts
@import './theme/icons';
@import './theme/icons.ed';

@import './theme/material';
@import './theme/material.override';

@import 'quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';
@import 'quill/dist/quill.bubble.css';
