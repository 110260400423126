
$icomoon-font-family: "iconED" !default;
$icomoon-font-path: "fonts/ed" !default;

$icon-ed-chevron: "\e90a";
$icon-ed-text-plan-correction: "\e90b";
$icon-ed-file-redelivery: "\e900";
$icon-ed-hero-image: "\e901";
$icon-ed-new-account: "\e902";
$icon-ed-new-epson: "\e903";
$icon-ed-packshot: "\e904";
$icon-ed-pdf: "\e905";
$icon-ed-plan-correction: "\e906";
$icon-ed-text-correction: "\e907";
$icon-ed-corrections: "\e927";
$icon-ed-pdf-repository: "\e925";
$icon-ed-upload: "\e926";
$icon-ed-visuals-isolation: "\e924";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?xslbra');
  src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?xslbra#iefix') format('embedded-opentype'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?xslbra') format('truetype'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?xslbra') format('woff'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?xslbra##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-ed-"],
[class*=" icon-ed-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-ed-chevron {
  &:before {
    content: $icon-ed-chevron;
  }
}

.icon-ed-text-plan-correction {
  &:before {
    content: $icon-ed-text-plan-correction;
  }
}

.icon-ed-file-redelivery {
  &:before {
    content: $icon-ed-file-redelivery;
  }
}

.icon-ed-hero-image {
  &:before {
    content: $icon-ed-hero-image;
  }
}

.icon-ed-new-account {
  &:before {
    content: $icon-ed-new-account;
  }
}

.icon-ed-new-epson {
  &:before {
    content: $icon-ed-new-epson;
  }
}

.icon-ed-packshot {
  &:before {
    content: $icon-ed-packshot;
  }
}

.icon-ed-pdf {
  &:before {
    content: $icon-ed-pdf;
  }
}

.icon-ed-plan-correction {
  &:before {
    content: $icon-ed-plan-correction;
  }
}

.icon-ed-text-correction {
  &:before {
    content: $icon-ed-text-correction;
  }
}

.icon-ed-corrections {
  &:before {
    content: $icon-ed-corrections;
  }
}

.icon-ed-pdf-repository {
  &:before {
    content: $icon-ed-pdf-repository;
  }
}

.icon-ed-upload {
  &:before {
    content: $icon-ed-upload;
  }
}

.icon-ed-visuals-isolation {
  &:before {
    content: $icon-ed-visuals-isolation;
  }
}
