[tabStatsAppearance] {
    .mat-mdc-tab-header {
        @apply h-12 border-b border-tw-disabled;

        .mat-mdc-tab-label-container{
                .mat-mdc-tab-list {
                    @apply uppercase;
    
                
                    .mat-mdc-tab {
                        @apply p-0 flex flex-row justify-center items-center;
    
                        &:nth-child(-n+2) {
                            @apply w-[25%];
                        }
    
                        &:nth-child(3) {
                            @apply w-1/2 border-l border-r-0 border-t-0 border-b-0 border-gray-400 bg-tw-success bg-opacity-10;
    
                        
                        }
    
                        &.mdc-tab-indicator--active {
                            @apply bg-tw-success text-white;
                            .mdc-tab__text-label {
                                @apply text-white;
                            }
                        }
    
                        .mdc-tab-indicator__content--underline {
                            @apply hidden;
                        }
    
    
    
                    }
    
                }
            

        }
    }
}

[tabAppearance] {
    .mat-mdc-tab-header {
        @apply h-12;

        .mat-mdc-tab-label-container{
                .mat-mdc-tab-list {
                    @apply uppercase;
    
                
                    .mat-mdc-tab {
                        @apply p-0 flex flex-row justify-center items-center;
    
                        
                        &.mdc-tab-indicator--active {
                            @apply bg-tw-success text-white;
                            .mdc-tab__text-label {
                                @apply text-white;
                            }
                        }
    
                        .mdc-tab-indicator__content--underline {
                            @apply hidden;
                        }
    
    
    
                    }
    
                }
            

        }
    }
}


[tabRequestAppearance] {
    .mat-tab-header {
        .mat-tab-label-container {
            .mat-tab-list {
                @apply mb-3;

                .mat-tab-labels {
                    @apply flex gap-3;

                    .mat-tab-label {
                        @apply p-0 flex flex-row justify-center items-center w-1/2 border-[1px] border-gray-400 rounded-lg;

                        &.mat-tab-label-active,
                        &.mat-tab-label-focused {
                            @apply bg-tw-primary text-white border-tw-primary opacity-100;
                        }

                        &:last-child {
                            @apply mr-0;
                        }


                    }
                }

                .mat-ink-bar {
                    @apply hidden;
                }

            }
        }
    }
}



.mat-error {
    @apply mt-3;

}

.label-grey {
    .mat-form-field-label {
        color: #9e9e9e !important; // Custom label color
    }
}

mat-select {
    .mat-select-trigger {
        @apply w-full;
    }

    .mat-select-arrow-wrapper {

        .mat-select-arrow {
            @apply hidden;

        }
    }
}






    







// .mat-form-field.mat-form-field-type-mat-input.mat-form-field-appearance-outline,
// .mat-form-field.mat-form-field-type-mat-select.mat-form-field-appearance-outline,
// .mat-form-field.mat-form-field-type-mat-chip-list.mat-form-field-appearance-outline {

//     @apply flex flex-col items-center h-auto justify-center;

//     .mat-form-field-wrapper {
//         @apply w-full h-auto block pb-0;
//     }

//     .mat-form-field-label-wrapper {
//         @apply flex flex-row justify-start items-center;

//         .mat-form-field-label {
//             @apply flex gap-1 #{!important};

//             &::before {
//                 content: "" !important;
//                 @apply w-[0.75rem] h-[0.75rem] bg-tw-dark-primary mt-[0.15rem] #{!important};
//             }
//         }
//     }

//     // État standard (valide et/ou non touché)
//     &:not(.ng-invalid),
//     &.ng-untouched {
//         .mat-form-field-outline {

//             .mat-form-field-outline-start,
//             .mat-form-field-outline-gap,
//             .mat-form-field-outline-end {
//                 border-color: var(--tw-primary);
//             }
//         }

//         .mat-form-field-label {
//             @apply text-tw-body-color #{!important};
//         }
//     }

//     // État focus
//     &:not(.ng-invalid).mat-focused {
//         .mat-form-field-outline {

//             .mat-form-field-outline-start,
//             .mat-form-field-outline-gap,
//             .mat-form-field-outline-end {
//                 border-color: var(--tw-dark-primary);
//             }
//         }
//     }

//     // État invalide
//     &:not(.ng-untouched).ng-invalid {
//         .mat-form-field-outline {

//             .mat-form-field-outline-start,
//             .mat-form-field-outline-gap,
//             .mat-form-field-outline-end {
//                 border-color: var(--tw-error);
//             }
//         }
//     }

//     &.mat-form-field-has-label.mat-form-field-can-float.mat-form-field-should-float {
//         .mat-form-field-label-wrapper {
//             @apply left-[-0.65rem];
//             transition: left 0.4s;

//             .mat-form-field-label {
//                 transform: translateY(-1.65em) scale(0.75);
//                 transition: transform 0.4s;
//             }
//         }

//         .mat-form-field-outline {
//             .mat-form-field-outline-gap {
//                 border-top-color: transparent;
//             }

//             .mat-form-field-outline-start {
//                 @apply flex flex-col border-none;

//                 &::before {
//                     content: "";
//                     @apply h-[0.9rem] border-transparent;
//                 }

//                 &::after {
//                     content: "";
//                     @apply w-full grow border-l-[1px] border-b-[1px] rounded-bl-[5px];
//                 }
//             }

//             &.mat-form-field-outline-thick {
//                 .mat-form-field-outline-start {
//                     &::after {
//                         @apply border-l-[2px] border-b-[2px];
//                     }
//                 }
//             }
//         }

//         // Couleur selon état pour les bordures flottantes
//         &:not(.ng-invalid).ng-valid,
//         &:not(.ng-invalid).ng-untouched,
//         &:not(.ng-invalid).ng-touched,
//         &.ng-pristine {
//             .mat-form-field-outline {
//                 .mat-form-field-outline-start {
//                     &::after {
//                         border-color: var(--tw-primary);
//                     }
//                 }
//             }

//             &:not(.ng-invalid).mat-focused {
//                 .mat-form-field-outline {
//                     .mat-form-field-outline-start {
//                         &::after {
//                             border-color: var(--tw-dark-primary);
//                         }
//                     }
//                 }
//             }
//         }

//         // État invalide
//         &:not(.ng-untouched).ng-invalid {
//             .mat-form-field-outline {
//                 .mat-form-field-outline-start {
//                     &::after {
//                         border-color: var(--tw-error) !important;
//                     }
//                 }
//             }
//         }
//     }
// }


// .mat-paginator-range-label {
//     margin: 0 20px 0 0 !important;
// }

// .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
//     border-color: var(--tw-primary);
// }

// .mat-radio-button.mat-accent .mat-radio-inner-circle,
// .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
// .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
// .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
//     background-color: var(--tw-primary);
// }

// .mat-slide-toggle.mat-accent.mat-checked .mat-slide-toggle-bar {
//     background-color: var(--tw-primary);

//     .mat-slide-toggle-thumb {
//         background-color: var(--tw-primary);
//         filter: brightness(1.15);
//     }
// }

// .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
// .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
// .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-background,
// .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-background {
//     background-color: var(--tw-primary);
// }


// OTHER OVERRIDES //
.mat-button-base {
    &.mat-grey {
        color: $dark-grey;
        background-color: rgba($grey, 0.5);
    }

    &.mat-primary {
        color: $grey;
        background-color: rgba($dark-green, 0.5);
    }

    &.mat-accent {
        color: $dark-green;
        background-color: rgba($accent, 0.5);
    }

    &.mat-warn {
        color: $red;
        background-color: rgba($red, 0.5);
    }

    &:disabled {
        filter: contrast(0.5) opacity(0.5);
    }
}


// TO KEEP //
//   // FIX TAILWIND BUG WITH ANGULAR MATERIAL //
.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
    border-right-style: hidden !important;
    border-left-style: hidden !important;
}

.mat-mdc-input-element {
    box-shadow: none !important;
}


// LIVECO APPEARANCE // 
// FILTERS //
[livecoAppearance] {
    mat-form-field {
        height: 3.5rem;
        
        .mdc-text-field {
            border-radius: 0.25rem;
        }

        .mat-mdc-form-field-bottom-align,
        .mat-mdc-form-field-focus-overlay {
            @apply h-0;
        }

        .mdc-text-field--filled:not(.mdc-text-field--disabled) {
            background-color: $white;
        }

        .mdc-line-ripple {
            display: none;
        }
    }
} 


// INPUT //

mat-label {
    @apply text-tw-text-dark #{!important};

    &::before {
        content: "" !important;
        @apply inline-block w-[0.75rem] h-[0.75rem] bg-tw-dark-primary mr-1 #{!important};
    }
}

.mat-mdc-notch-piece {
    @apply border-tw-primary #{!important};

}
    
.mat-mdc-form-field-infix{
    input, textarea, mat-select{
        @apply text-tw-primary font-bold #{!important};

    }

}

.mdc-text-field--disabled{
    input{
        @apply text-tw-text-dark #{!important};
    }
}

.mat-mdc-unelevated-button[disabled], 
.mat-mdc-unelevated-button.mat-mdc-button-disabled,
.mat-mdc-raised-button[disabled].mat-mdc-button-disabled,
.mat-mdc-raised-button.mat-mdc-button-disabled.mat-mdc-button-disabled {
  cursor: default;
  pointer-events: none;
  color: var(--mdc-filled-button-disabled-label-text-color) !important;
  background-color: var(--mdc-filled-button-disabled-container-color) !important;
}

.mdc-text-field--invalid{
    .mat-mdc-notch-piece{
        @apply border-tw-error border-2 #{!important};
    }
    
}

.mat-mdc-dialog-container .mat-mdc-dialog-title{
    @apply text-2xl #{!important};
}

.mat-badge-content{
    @apply font-sans text-xs leading-[22px] text-white #{!important};
}

.mdc-checkbox__checkmark{
    @apply text-white #{!important};
}

