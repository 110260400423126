$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);

// Fonts
:root {
  --font-family-sans-serif: 'Gordita Regular', -apple-system, system-ui,
    BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial, sans-serif;
  --font-family-base: $font-family-sans-serif;
  --tw-primary: #709A9D;
  --tw-dark-primary: #04636a;
  --tw-secondary: #DE5F4D;
  --tw-complementary: #fef7ea;
  --tw-disabled: #E3E3E3;
  --tw-lightgrey: #F5F5F5;
  --tw-text-disabled: #555555;
  --tw-success: #A5D256;
  --tw-error: #e35d54;
  --tw-text-light: #FFFFFF;
  --tw-text-dark: #000000;
  --tw-blue: #3BBFE8;
  --tw-darkblue: #2098BE;
  --tw-mediumgrey: #878787;
  --tw-body-color: #575c62;
}

// Spacing
$container-max-width: 1400px;
$borderContainerSpacing: 50px;
$paddingApp: 30px;

// Colors
$white: #ffffff;
$dark-white: #f6f6f6;
$black: #4c4c4c;
$dark: #191919;
$light-black: #3d3d3d;
$dark-grey: #2c2c2c;
$light-grey: #dddee3;
$light-grey-200: #efececf5;
$grey: #b9bfd1;
$status-grey: grey;
$accent: #a5d256;
$primary: #2e8d54;
$warn: #EF9A9A;
$yellow: #FFE082;
$light-yellow: #FFF59D;
$dark-brown: #805229;

$light-green: #d2e9ab;
$green: #a5d256;
$dark-green: #708f39;

$light-cyan-green: #88ce75;
$cyan-green: #6dc991;

$light-cyan: #4ce78a;
$cyan: #3bb76d;
$dark-cyan: #2e8d54;

$light-red: #eaa09b;
$red: #e35d54;
$dark-red: #993d37;

$light-purple: #b59eff;
$purple: #8c6ff0;
$dark-purple: #4e3d87;

$pale-yellow: #fff7ea;
$blue-grey: #2b3d3d;
$light-blue-grey: #719a9e;

$light-blue: #abd2e8;
$blue: #56a5d2;
$dark-blue: #0f3e7c;

$light-orange: #ecac73;
$orange: #f3a057;
$dark-orange: #87562a;

$pink: #fa0f9c;
$dark-pink: #8c0355;
$packZ: #ab1d53;

$diadem-blue: #709A9D;
$diadem-red: #DE5F4D;


// Colors components
$calBorderColor: $light-grey;
$calBackgroundColor: $light-grey;
$navBackgroundColor: $accent;

// Material Colors
$palette-primary-base: (
  50: #ebf4fa,
  100: #cce4f2,
  200: #abd2e9,
  300: #89c0e0,
  400: #6fb3d9,
  500: #56a5d2,
  600: #4f9dcd,
  700: #4593c7,
  800: #3c8ac1,
  900: #2b79b6,
  A100: #f7fbff,
  A200: #c4e4ff,
  A400: #91ccff,
  A700: #78c1ff,
  contrast: (50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$palette-accent-base: (
  50:#e3f3f6,
  100:#c8dee0,
  200:#aac7ca,
  300:#8aafb3,
  400:#729ca1,
  500:#598a90,
  600:#4e7a7f,
  700:#3f656a,
  800:#325256,
  900:#213c3f,
  // A100:#A7FFEB,
  // A200:#64FFDA,
  // A400:#1DE9B6,
  contrast: (50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$palette-warn-base: (
  50: #fcecea,
  100: #f7cecc,
  200: #f1aeaa,
  300: #eb8e87,
  400: #e7756e,
  500: #e35d54,
  600: #e0554d,
  700: #dc4b43,
  800: #d8413a,
  900: #d03029,
  A100: #ffffff,
  A200: #ffdddc,
  A400: #ffaca9,
  A700: #ff9390,
  contrast: (50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);