h2{
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.5;
    margin: 0;
}
.logo {
    cursor: pointer;
}

.btn .mat-button-wrapper .icon {
    font-size: 2em;
}

.no-arrow .mat-expansion-indicator {
    display: none !important;
}

button.mat-button {
    padding-left: 16px !important;
    padding-right: 16px !important;
}

// .version-chip:not(.selected) {
//     @apply whitespace-nowrap cursor-pointer min-h-fit h-fit px-2 py-0 bg-tw-disabled text-tw-darkblue hover:bg-tw-darkblue hover:text-tw-lightgrey #{!important};
// }

.version-chip{
    @apply mt-0 #{!important};

    &.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) .mdc-evolution-chip__text-label{
        @apply whitespace-nowrap cursor-pointer min-h-fit h-fit text-tw-darkblue #{!important};
    }

    &:hover, &.selected{
        @apply  bg-tw-darkblue #{!important};
        &.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) .mdc-evolution-chip__text-label{
            @apply text-tw-lightgrey #{!important};
        }
    }
    
}



.color-box {

    @apply flex gap-1 items-start;

    &::before {
        @apply size-3 bg-tw-dark-primary mt-1;
        content: '';
        display: block;
    }
}

.project {


    .mat-input-element,
    .mat-select-value {
        @apply text-tw-primary #{!important};


        &:disabled {
            @apply text-tw-text-dark #{!important};
        }
    }

    .mat-select-disabled {
        .mat-select-value {
            @apply text-tw-text-dark #{!important};
        }
    }



}

.stats-title {
    @apply p-2 bg-tw-primary text-white;
}

.single-tooltip {
    word-break: break-all !important;
    white-space: normal !important;
}


// Colors
.white {
    color: $white;
}

.black {
    color: $black;
}

.dark-grey {
    color: $dark-grey;
}

.light-grey {
    color: $light-grey;
}

.grey {
    color: $grey;
}

.green {
    color: $green;
}

.dark-green {
    color: $dark-green;
}

.cyan {
    color: $cyan;
}

.red {
    color: $red;
}

.purple {
    color: $purple;
}

.dark-purple {
    color: $dark-purple;
}

.blue {
    color: $blue;
}

.dark-blue {
    color: $dark-blue;
}

.accent {
    color: $accent;
}

.primary {
    color: $primary;
}

.warn {
    color: $warn;
}

// Elements
.sep {
    display: block;
    width: 100%;
    height: 1px;
    background-color: $grey;
    opacity: 0.5;
}

button.mat-menu-item.nav-menu-item {
    font-weight: bold;
    font-size: 0.8rem;
    color: grey;
    transition: all 0.2s ease-in-out;

    mat-icon {
        color: grey !important;
    }

    span {
        transform: translateY(1px);
        display: inline-block;
    }

    &.deconnexion {
        background-color: rgba($light-red, 0);
        color: $red;

        mat-icon {
            color: $red !important;
        }

        &:hover {
            background-color: rgba($light-red, 0.2);
        }
    }
}

.card {
    background-color: white;
    border-radius: 8px;
}

//CK Editor

.ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused {
    border: none !important;
    box-shadow: none !important;
}

.ck-editor__editable_inline:not(.ck-comment__input *) {
    @apply min-h-24;
    overflow-y: auto;
}

/* Dans le fichier SCSS du composant */
.ck.ck-reset_all {
    position: absolute !important;
    z-index: 1050;
    /* Valeur supérieure au z-index du modal */
}

.textAreaButtons {
    top: 0px !important;
}

.splitviewFullScreen {
    position: absolute;
    height: 100vh;
    width: 100%;
    z-index: 1000;
}


// FORMS //
.form-row {
    @apply flex w-full justify-between pr-40;

    mat-form-field,
    app-autocomplete-filter {
        @apply w-[45%] items-center;
    }
}

.separator {
    @apply border-t-[1px] border-gray-300 h-[1px];
}

.form-subtitle {
    @apply text-2xl font-bold text-tw-primary;

}


/* Scrollbar styles */
::-webkit-scrollbar {
    width: 0.625rem;
    height: 0.625rem;
}

::-webkit-scrollbar-track {
    background: #f5f5f5;
    border-radius: 0.625rem;
}

::-webkit-scrollbar-thumb {
    border-radius: 0.625rem;
    background: #ccc;
}

::-webkit-scrollbar-thumb:hover {
    background: #999;
}


// GENERAL CLASS DECLARATIONS //
// TODO: keep this section, sort the class above to see if they can be moved here
.container {
    @apply max-w-screen-2xl m-auto p-8;
}

.heading {
    @apply text-4xl font-extrabold leading-none tracking-tight;
}


// MOZILLA BLUE 
*:focus {outline:0px none transparent;}

//coco