// TO KEEP


// INTERN

// .<state_slug> is color to write text in the bar in splitview-intern
// .<state_slug> & .btn for border and hover behavior

// .bg-<state_slug> is color to use in the background bar in splitview-intern + on the dot in dashboard list

.wip,
.check-auto-control,
.calculating,
.generating,
.control-validated,
.intern-finished,
.intern-finished-operator,
.control-delivery-validated,
.pr-client-inProgress {
    color: $primary !important;

    &.btn {
        border: 1px solid $primary !important;

        &:hover {
            background: $primary !important;
            color: $white !important;

            ::before {
                color: $white !important;
            }
        }
    }
}

.bg-wip,
.bg-check-auto-control,
.bg-calculating,
.bg-generating,
.bg-control-validated,
.bg-control-delivery-validated,
.bg-intern-finished,
.bg-intern-finished-operator,
.bg-pr-client-inProgress {
    color: $white !important;
    background-color: $primary !important;
}

.check-prod,
.check-repro,
.check-quality,
.to-deliver,
.re-deliver {
    color: $light-purple !important;

    &.btn {
        border: 1px solid $light-purple !important;

        &:hover {
            background: $light-purple !important;
            color: $dark-purple !important;

            ::before {
                color: $dark-purple !important;
            }
        }
    }
}

.bg-check-quality,
.bg-check-repro,
.bg-check-prod,
.bg-to-deliver,
.bg-re-deliver {
    color: $dark-purple !important;
    background-color: $light-purple !important;
}

.check-cpp {
    color: $light-orange !important;

    &.btn {
        border: 1px solid $light-orange !important;

        &:hover {
            background: $light-orange !important;
            color: $dark-brown !important;

            ::before {
                color: $dark-brown !important;
            }
        }
    }
}

.bg-check-cpp {
    color: $dark-brown !important;
    background-color: $light-orange !important;
}

.none,
.readonly {
    color: $status-grey !important;

    &.btn {
        border: 1px solid $status-grey !important;

        &:hover {
            background: $status-grey !important;
            color: $black !important;

            ::before {
                color: $black !important;
            }
        }
    }
}

.bg-none,
.bg-readonly {
    color: $black !important;
    background-color: $status-grey !important;
}

.cpp-intern-rejected {
    color: $dark-orange !important;

    &.btn {
        border: 1px solid $dark-orange !important;

        &:hover {
            background: $dark-orange !important;
            color: $white !important;

            ::before {
                color: $white !important;
            }
        }
    }
}

.bg-cpp-intern-rejected {
    color: $white !important;
    background-color: $dark-orange !important;
}

.ready,
.in-progress,
.post-validation {
    color: $dark-blue !important;

    &.btn {
        border: 1px solid $dark-blue !important;

        &:hover {
            background: $dark-blue !important;
            color: $dark-blue !important;

            ::before {
                color: $light-blue !important;
            }
        }
    }
}

.bg-ready,
.bg-in-progress,
.bg-post-validation {
    color: $dark-blue !important;
    background-color: $light-blue !important;
}

.prod-intern-rejected,
.control-failed,
.control-delivery-failed {
    color: $red !important;

    &.btn {
        border: 1px solid $red !important;

        &:hover {
            background: $red !important;
            color: $white !important;

            ::before {
                color: $white !important;
            }
        }
    }
}

.bg-prod-intern-rejected,
.bg-control-failed,
.bg-control-delivery-failed {
    color: $white !important;
    background-color: $red !important;
}


// EXTERN

.null {
    color: $black !important;

    &.btn {
        border: 1px solid $black !important;

        &:hover {
            background: $black !important;
            color: $light-grey !important;

            ::before {
                color: $light-grey !important;
            }
        }
    }
}

.bg-null {
    color: $light-grey !important;
    background-color: $black !important;
}

.send-pr-client {
    color: $blue-grey !important;

    &.btn {
        border: 1px solid $blue-grey !important;

        &:hover {
            background: $blue-grey !important;
            color: $pale-yellow !important;

            ::before {
                color: $pale-yellow !important;
            }
        }
    }
}

.bg-send-pr-client {
    color: $pale-yellow !important;
    background-color: $blue-grey !important;
}

.send-pr-cpp {
    color: $light-orange !important;

    &.btn {
        border: 1px solid $light-orange !important;

        &:hover {
            background: $light-orange !important;
            color: $dark-orange !important;

            ::before {
                color: $dark-orange !important;
            }
        }
    }
}

.bg-send-pr-cpp {
    color: $dark-orange !important;
    background-color: $light-orange !important;
}


.init,
.paused,
.restart {
    color: $status-grey !important;

    &.btn {
        border: 1px solid $status-grey !important;

        &:hover {
            background: $status-grey !important;
            color: $black !important;

            ::before {
                color: $black !important;
            }
        }
    }
}

.bg-init,
.bg-paused,
.bg-restart {
    color: $white !important;
    background-color: $status-grey !important;
}

.feedback-client,
.feedback-info {
    color: $light-blue-grey !important;

    &.btn {
        border: 1px solid $light-blue-grey !important;

        &:hover {
            background: $light-blue-grey !important;
            color: $blue-grey !important;

            ::before {
                color: $blue-grey !important;
            }
        }
    }
}

.bg-feedback-client,
.bg-feedback-info {
    color: $white !important;
    background-color: $light-blue-grey !important;
}

.rejected,
.reject,
.closed {
    color: $red !important;

    &.btn {
        border: 1px solid $red !important;

        &:hover {
            background: $red !important;
            color: $white !important;

            ::before {
                color: $white !important;
            }
        }
    }
}

.bg-rejected,
.bg-reject,
.bg-closed {
    color: $white !important;
    background-color: $red !important;
}



.accept,
.approve,
.approved,
.accepted,
.delivered,
.in-production {
    color: $primary !important;

    &.btn {
        border: 1px solid $primary !important;

        &:hover {
            background: $primary !important;
            color: $white !important;

            ::before {
                color: $white !important;
            }
        }
    }
}

.bg-accept,
.bg-approve,
.bg-accepted,
.bg-approved,
.bg-delivered,
.bg-in-production {
    color: $white !important;
    background-color: $primary !important;
}


.paused-R {
    color: $status-grey !important;
}

.bg-paused-R {
    color: $black !important;
    background-color: $status-grey !important;
}


// MEMBER STATUS
.inProgress {
    color: $light-blue !important;
}

.bg-inProgress {
    color: $white !important;
    background-color: $light-blue !important;
}

.readOnly {
    color: $status-grey !important;
}

.bg-readOnly {
    color: $black !important;
    background-color: $status-grey !important;
}

.readOnlyProofscope {
    color: $status-grey !important;
}

.bg-readOnlyProofscope {
    color: $black !important;
    background-color: $status-grey !important;
}

.pending {
    color: $status-grey !important;
}

.bg-pending {
    color: $black !important;
    background-color: $status-grey !important;
}

.auto-control {
    color: $primary !important;
}

.bg-auto-control {
    color: $white !important;
    background-color: $primary !important;
}


// EZD REQUEST
.project_linked {
    color: $accent !important;
}

.bg-project_linked {
    background-color: $accent !important;
}